<template>
  <main-card class="payment-add" body-align="bottom">
    <!--    <base-input v-model="amount" mask="9{1,10}">-->

    <InputNumber
      v-model="amount"
      mode="currency"
      currency="RUB"
      :max="99999999"
      locale="ru-RU"
      class="p-inputtext-lg"
    />
    <!--      currency-display="code"-->
    <!--    <base-input v-model="amount">-->
    <!--      {{ $t('balance.label') }}-->
    <!--    </base-input>-->
    <template #bodyEnd>
      <base-button @click="onClick">
        {{ $t('balance.add') }}
      </base-button>
    </template>
  </main-card>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import addPayment from '@/mixins/billing/addPayment';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'PaymentAdd',
  components: { /* BaseInput,*/ InputNumber },
  mixins: [addPayment],
  computed: {
    changeValue() {
      let newConfig = this.amount;
      // newConfig.mask = '9{1,10}';

      newConfig = this.amount('.')
        ? this.amount.replace('.', ',')
        : this.amount === ''
        ? 0
        : this.amount;
      console.log(newConfig);
      return newConfig;
    },
  },
  methods: {
    onClick() {
      if (!this.amount) return;
      this.addPayment();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "balance": {
      "title": "Пополнить баланс",
      "label": "Сумма",
      "add": "Пополнить"
    },
    "modal": {
      "res": {
        "success": "Запрос успешно отправлен. Пожалуйста, дождитесь обновления данных",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
